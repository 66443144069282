<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'AdminPage' }">← Назад</router-link><br><br>
		<h1 class="heading">Доступные отчеты по вебинарам</h1>
		<h2 class="subheading heading__hr">Прошедшие вебинары</h2>
		<ul class="past-webinars--list">
			<li v-for="webinar, key in getStoppedWebinars" :key="'webinar-past'+key">
				<router-link :to="{ name: 'ReportPage', params: { id: webinar.id } }">
					<webinar :webinar_data="webinar" :is_past="true" :no_btns="true"/>
				</router-link>
			</li>
		</ul>
		<br><br>
		<h2 class="subheading heading__hr">Запланированные вебинары</h2>
		<ul class="past-webinars--list">
			<li v-for="webinar, key in getActiveWebinars" :key="'webinar-planned'+key">
				<router-link :to="{ name: 'ReportPage', params: { id: webinar.id } }">
					<webinar :webinar_data="webinar" :no_btns="true"/>
				</router-link>
			</li>
		</ul>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Webinar from '@/components/cards/Webinar.vue'
export default {
  components: { Webinar },
	computed: {
		...mapGetters(['getStoppedWebinars', 'getActiveWebinars']),
	},
	methods: {
		...mapActions(['fetchAllWebinarsFromWebinarRu'])
	},
	created() {
		if(this.getActiveWebinars.length == 0) this.fetchAllWebinarsFromWebinarRu()
	}
}
</script>

<style>
	.past-webinars--list li a {
		text-decoration: none;
	}
	.past-webinars--list li a * {
		cursor: unset;
		pointer-events: none;
	}
</style>
